import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Services
import { getAllFeaturesByType } from '../../../services/apis/feature'
import { createProfilesByList } from '../../../services/apis/profile'
import { updateRecord } from '../../../services/apis/record'

// Components
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import TitleMessage from '../../../components/shared/TitleMessage'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'
import { getRecord } from '../../../common/methods/storage'

// Constants
import { PAGES } from '../../../constants/Pages'
import { NEED_NAMES, needs } from '../../../constants/lists/needs'
import { REACTIONS } from '../../../constants/reactions'

// Images
import verbConstelation from '../../../assets/images/constelation-selection-verbs.svg'
import { isMobile } from '../../../common/methods/devices'

const Verbs = () => {
  const navigate = useNavigate()

  const { token } = useParams()

  const { url } = getWorkspaceParams()
  const record = getRecord()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [features, setFeatures] = useState<any>([])
  const [selectedList, setSelectedList] = useState<any[]>([])
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)

  const [currentPage, setCurrentPage] = useState(14)

  const [title, setTitle] = useState<string>('')

  const VERBS_POSITIONS = [
    { position: 'bottom-[100px] left-[93px] max-[480px]:bottom-[32%] max-[480px]:left-[20px] xl:bottom-[135px] xl:-left-[170px]' },
    { position: 'bottom-[99px] right-[78px] max-[480px]:bottom-[32%] max-[480px]:right-[20px] xl:bottom-[135px] xl:-right-[190px]' },
    { position: 'bottom-[220px] right-[164px] max-[480px]:bottom-[55%] max-[480px]:right-[40px] xl:bottom-[350px] xl:-right-[70px]' },
    { position: 'bottom-[210px] left-[152px] max-[480px]:bottom-[55%] max-[480px]:left-[40px] xl:bottom-[340px] xl:-left-[60px]' }
  ]

  const baseStep = 14

  useEffect(() => {
    getFeatures()
    getVerbs()
    setSteps()
    setTimeout(() => { setIsLoading(false) }, 800)
  }, [token])

  const getVerbs = () => {
    if (!record) return

    let verbs: any[] = record.features.filter((fet: any) => fet.type === token && fet.profiles.reaction === REACTIONS.LOVE)

    verbs = verbs.map((verb: any) => ({
      ...verb,
      feature: verb,
      weight: verb.profiles.weight,
      feature_id: verb.id,
      reaction: verb.profiles.reaction,
      selected: verb.profiles.selected
    }))

    setSelectedList([...verbs ?? []])

    setTitle(NEED_NAMES.find((verb: any) => verb.code === token)?.pageTitle ?? '')
  }

  const onSetSelectedList = (data: any) => {
    const selected_list = selectedList
    const index = selected_list.findIndex(sl => sl.id === data.id)

    if (index === -1) selected_list.push({ ...data, feature: data, reaction: REACTIONS.LOVE, feature_id: data.id })
    else selected_list[index].reaction = selected_list[index].reaction === REACTIONS.LOVE ? REACTIONS.DISLIKE : REACTIONS.LOVE

    setSelectedList([...selected_list])
  }

  const getFeatures = async () => {
    if (!token) return

    await getAllFeaturesByType(token, true)
      .then(result => {
        setFeatures(result.data)
      })
  }

  const isContainKey = (data: any) => selectedList.findIndex(sl => sl.id === data.id && sl.reaction === REACTIONS.LOVE) !== -1

  const onGoToNextStep = async () => {
    setIsButtonDisabled(true)

    const selected_list = selectedList
    if (selected_list.filter((sl: any) => sl.reaction === REACTIONS.LOVE).length === 1) {
      const index = selected_list.findIndex((sl: any) => sl.reaction === REACTIONS.LOVE)
      selected_list[index].weight = 4
      selected_list[index].selected = true
    } else {
      selected_list.forEach((item: any) => {
        if (item.reaction === REACTIONS.DISLIKE) {
          item.selected = false
          item.weight = null
        }
      })
    }

    try {
      const createProfilesResponse = await createProfilesByList(selected_list)
      console.log('Create Profiles Response:', createProfilesResponse)

      if (createProfilesResponse.status) {
        const updateResponse = await updateRecord({ step: currentPage + (selected_list.length === 1 ? 2 : 1) })
        console.log('Update Record Response:', updateResponse)

        setIsLoading(true)
        const link = getNextLink(selected_list)
        navigate(link)
      }
    } catch (error) {
      console.error('Error in onGoToNextStep:', error)
    } finally {
      setIsButtonDisabled(false)
    }
  }

  const setSteps = () => {
    const need_index = needs.findIndex(nd => nd === token!)
    setCurrentPage(14 + (need_index * 2))
  }

  const goToPreviousLink = () => {
    let previousURL = ''

    if (!token || !record) return previousURL
    const need_index = (currentPage - baseStep) / 2

    if (need_index === 0) {
      previousURL = PAGES.CONVERSATION_3
    } else {
      const previous_need = needs[need_index - 1]
      const verbsPrevious = record.features.filter((feature: any) => feature.type === previous_need && feature.profiles.reaction === REACTIONS.LOVE)

      if (verbsPrevious.length === 1) previousURL = `/verbs/${previous_need}`
      else previousURL = `/needs/${previous_need}`
    }

    return previousURL
  }

  const getNextLink = (select_list: any) => {
    let nextURL = `/${url}`

    if (!token) return nextURL

    const need_index = (currentPage - baseStep) / 2
    const loveReactionCount = select_list.filter((sl: any) => sl.reaction === REACTIONS.LOVE).length

    if (loveReactionCount === 1) nextURL += (need_index === 5 ? (isMobile() ? PAGES.PROFILES : PAGES.DECISION_2) : `/verbs/${needs[need_index + 1]}`)
    else nextURL += `/needs/${token}`

    return nextURL
  }

  const getDataPositionByParent = (index: number) => {
    return VERBS_POSITIONS.at(index)?.position ?? ''
  }

  return (
    <article className="bg-main" id="home">
      <div className="w-screen h-screen quiz-screen-background bg-feature-small py-4 flex flex-col">
        <ButtonRedirect action="return" linkReturn={goToPreviousLink()} text="return" />
        <BadgeStep currentStep={currentPage} />
        {
          selectedList.filter((sl: any) => sl.reaction === REACTIONS.LOVE).length > 0 &&
          <ButtonRedirect
            action="next"
            linkReturn=""
            text="next"
            callback={onGoToNextStep}
            loading={isButtonDisabled}
          />
        }
        <TitleMessage
          index={0}
          useLoading={false}
          title={title}
          size="w-1/2 max-w-[800px] h-auto pl-[10%]"
          message={isMobile() ? 'Puedes seleccionar más de una opción.' : 'Puedes seleccionar más de una opción pero<br />piensa bien qué es lo que REALMENTE quieres.'}
        />
        {
          !isLoading &&
          <div className='content-background animation-charge w-[900px] m-auto flex-1 py-4 px-[35px] relative flex items-end justify-center max-w-full'>
            { verbConstelation && !isMobile() && <img alt="" className="absolute bottom-[33px] w-[360px] xl:w-[600px]" src={verbConstelation} />}
              {
                  features?.map((feature: any, index: number) => {
                    return (
                      <button key={feature.id} onClick={() => onSetSelectedList(feature)} className={`absolute text--webkit cursor-pointer z-[450] transition ease-in card-verb-${index} ${getDataPositionByParent(index)}`}>
                          <div className={`w-[155px] xl:w-[240px] sticky z-50 transition-all ease-in duration-[150ms] bg-contain bg-no-repeat bg-center card-verb h-[70px] hover:h-[108px] xl:h-[135px] xl:hover:h-[185px] ${isContainKey(feature) ? `card-verb-selected-${index}` : ''}`}>
                              <div className={'w-full h-full flex flex-col justify-start items-start gap-0 pb-8 max-[480px]:px-[12px] px-[14px] xl:px-6 overflow-hidden max-[480px]:pt-[25px] pt-[42px] xl:pt-[78px]'}>
                                <div className="max-[480px]:w-full max-[480px]:justify-center flex flex-1 items-center ff-cp--bold max-[480px]:text-center max-[480px]:text-[13px] max-[480px]:leading-[14px] text-[13.5px] xl:text-[20px] leading-[16px] mb-[2px] xl:mb-0 xl:leading-[14px] text-white text-left">
                                  {feature.name}
                                </div>
                                <div className="flex flex-1 ff-cp--regular max-[480px]:text-[10.5px] text-[11px] xl:text-[16px] leading-[11px] xl:leading-[16px] text-white text-left transition-all ease-in card-description">
                                  {feature.description}
                                </div>
                              </div>
                          </div>
                      </button>
                    )
                  })
                }
          </div>
        }
      </div>
    </article>
  )
}

export default Verbs
