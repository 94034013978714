import React, { useEffect, useState } from 'react'
import Joyride from 'react-joyride'

// Services
import { getAllCareersWithClassification } from '../../../services/apis/career'
import { setFavouriteStatus, setTutorialStatus } from '../../../services/apis/record'
import { setDemoCareerStatus } from '../../../services/apis/user'

// Components
import CardCareer from '../../../components/cards/CardCareer'
import IntroTooltip from '../../../components/intro/IntroTooltip'
import DashboardMenu from '../../../components/shared/DarshboardMenu'

// Constants
import { ADMINISTRATION, EDUCATION_LEVEL } from '../../../constants/Administration'
import { CAREER_CLASSIFICATION } from '../../../constants/classification'
import { CAREER_1, CAREER_2, CAREER_3, STEPS_1, STEPS_2, STEPS_3, STEPS_4 } from '../../../constants/tutorial'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'
import { getCareerPage, getDemoCareerStatus, getFavouriteCourses, getHasReferences, getRecord, getRecordCareers, getTutorialStatus, getTutorialStep, getUser, setRecord, setUser } from '../../../common/methods/storage'

// Images
import avatarDefault from '../../../assets/images/avatar-default.svg'
import btnViewLess from '../../../assets/images/buttons/view_less_careers_btn.svg'
import btnViewMore from '../../../assets/images/buttons/view_more_careers_btn.svg'
import iconDoubleArrow from '../../../assets/images/icons/icon-double-arrow.svg'
import iconSearch from '../../../assets/images/icons/icon-search.svg'
import iconLoading from '../../../assets/images/icons/icon-spinner.svg'
import logoCareers from '../../../assets/images/logos/logo-careers.svg'
import { isMobile } from '../../../common/methods/devices'
import TitleMessage from '../../../components/shared/TitleMessage'

const DashboardCareer = () => {
  const { url } = getWorkspaceParams()

  const user = getUser()
  const record = getRecord()

  const firstTutorialStatus = getTutorialStatus()
  const secondTutorialStatus = getDemoCareerStatus()
  const tutorialStepList = getTutorialStep()

  const [allowCharge, setAllowCharge] = useState<boolean>(getRecordCareers().length <= 0)
  const [checkboxEnabled, setCheckboxEnabled] = useState<boolean>(true)

  const [careers, setCareers] = useState<any[]>(getRecordCareers())
  // const [tutorialCareers, setTutorialCareers] = useState<any[]>([])
  // const [showCareers, setShowCareers] = useState<any[]>([])
  const [menuList, setMenuList] = useState<number[]>([])
  const [currPage, setCurrPage] = useState<number>(
    getCareerPage() >= 0 && !getFavouriteCourses() ? getCareerPage() : 0
  )

  const [administrationOptions, setAdministrationOptions] = useState<any>(
    localStorage.getItem('tcm_administration_options') ? JSON.parse(localStorage.getItem('tcm_administration_options') ?? '') : ADMINISTRATION[0])
  const [levelOptions, setLevelOptions] = useState<any>(
    localStorage.getItem('tcm_level_options') ? JSON.parse(localStorage.getItem('tcm_level_options') ?? '') : EDUCATION_LEVEL[0]
  )
  const [pregradeValue, setPregradeValue] = useState(localStorage.getItem('tcm_pregrade') === 'true')
  const [favouriteCourses, setFavouriteCourses] = useState<boolean>(
    firstTutorialStatus && getRecordCareers().length <= 0
      ? getHasReferences()
      : getFavouriteCourses()
  )

  const [search, setSearch] = useState<string | undefined>(localStorage.getItem('tcm_search_career') ?? undefined)

  const [showLevelPopup, setShowLevelPopup] = useState<boolean>(false)
  const [showAdministrationPopup, setShowAdministrationPopup] = useState<boolean>(false)

  const [run, setRunTutorial] = useState<boolean>(false)
  const [stepList, setStepList] = useState<any>([])
  // const [tutorialStep2List, setTutorialStep2List] = useState<any[]>([])
  const [step, setStepTutorial] = useState<number>(0)
  const [demoFavorite, setDemoFavorite] = useState<boolean>(false)
  const [demoHover, setDemoHover] = useState<boolean>(false)
  // const [existSelected, setExistSelected] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingTutorial, setLoadingTutorial] = useState<boolean>(true)

  const handlePregrade = () => {
    setValues(!pregradeValue, setPregradeValue)
    localStorage.setItem('tcm_pregrade', (!pregradeValue).toString())
  }

  const setValues = (value: any, setter: React.Dispatch<React.SetStateAction<any>>) => {
    setter(value)
    setCareers([])
    setCurrPage(0)
    setAllowCharge(true)
  }

  const getCareers = (signal?: AbortSignal, nameSearch?: string) => {
    if ((allowCharge || nameSearch !== undefined)) {
      setLoading(true)
      setCheckboxEnabled(false)
      getAllCareersWithClassification({
        gestion: administrationOptions.code,
        level: levelOptions.code,
        graduate: pregradeValue,
        selected: favouriteCourses,
        page: nameSearch ? 0 : currPage,
        name: nameSearch ?? search,
        order: false,
        profiles: [],
        noun: null,
        signal
      })
        .then(data => {
          if (data.status) {
            let _careers: any[] = []

            _careers = careers
            const current_careers = data.careers

            current_careers.forEach((career: any) => {
              const index = _careers?.findIndex((cr: any) => cr.career_id === career.career_id)
              if (index === -1) _careers.push(career)
            })

            setRecord({
              ...record,
              tutorial_step: data.tutorial_step,
              favourite_courses: favouriteCourses,
              careers: _careers,
              menu_list: firstTutorialStatus ? data.menu_list : []
            })
            setCareers([..._careers])
            // setTutorialCareers([..._careers])
            // setShowCareers([..._careers])

            if (data.tutorial_step !== 'STEP_2') {
              setMenuList([...firstTutorialStatus ? data.menu_list : []])
              if (firstTutorialStatus && !secondTutorialStatus) {
                setRunTutorial(false)
              }
            }
            if (run && secondTutorialStatus && tutorialStepList !== 'STEP_2') {
              setStepTutorial(step + 1)
            }
            setLoading(false)
          }
        })
        .finally(() => {
          setAllowCharge(false)
          setCheckboxEnabled(true)
          setLoadingTutorial(false)
        })
    }
  }

  const handleSearch = (event: any) => {
    setCareers([])
    setCurrPage(0)
    localStorage.setItem('tcm_search_career', event.target.value)
    setSearch(event.target.value)
  }

  const onDeselectedFavouriteCareer = (id: string) => {
    let currentCareers = careers
    if (favouriteCourses) {
      currentCareers = currentCareers.filter(career => career.career_id !== id)
    } else {
      const findIndex = currentCareers.findIndex(career => career.career_id === id)
      currentCareers[findIndex].career_reference_id = null
    }
    // setShowCareers([...currentCareers])
    setCareers([...currentCareers])
    setRecord({
      ...record,
      careers: currentCareers
    })
  }

  const onSelectedFavouriteCareer = (career_id: string, career_reference_id: string) => {
    const currentCareers = careers

    const findIndex = currentCareers.findIndex(career => career.career_id === career_id)
    currentCareers[findIndex].career_reference_id = career_reference_id

    // setShowCareers([...currentCareers])
    setCareers([...currentCareers])
    setRecord({
      ...record,
      careers: currentCareers
    })
  }

  const setTutorialByStep2 = ({ record } : { record: any }) => {
    if (tutorialStepList === 'STEP_2') {
      if (firstTutorialStatus) {
        const restTutorial = STEPS_2.map(step => step)
        const newTutorialSteps = restTutorial.filter(step => careers.map(career => career.classification).includes(step.title.toUpperCase()))
        newTutorialSteps.push(
          {
            target: '.career-success',
            title: '',
            content: 'Selecciona una carrera ideal, óptima o complementaria y comienza a explorar. Para ello debes seleccionar “Ver instituciones”.',
            floaterProps: {
              hideArrow: true
            },
            placement: 'center'
          },
          {
            target: '.career-success',
            title: 'latest',
            content: '',
            floaterProps: {
              hideArrow: true
            },
            placement: 'center'
          }
        )
        if (newTutorialSteps.length) {
          setStepList([...newTutorialSteps])
          setLoadingTutorial(false)
          setTutorialStatus(true)
          setRunTutorial(true)
          if (newTutorialSteps.at(-1)?.title.toUpperCase() === record.lastCareerType) {
            // setTutorialStatus(false)
            setRecord({
              ...record,
              favourite_courses: false
              // demo: false
            })
          } else {
            setRecord({
              ...record,
              demo: true
            })
          }
        }
      }
    }
  }
  useEffect(() => {

  }, [stepList])

  const filterMenuSteps = () => {
    if (menuList.length > 0) {
      let filteredSteps: any[] = []
      const current_step: string = tutorialStepList

      if (current_step === 'STEP_1') {
        filteredSteps = STEPS_1
      }
      if (current_step === 'STEP_3') {
        filteredSteps = STEPS_3.filter((step: any, index: number) => menuList.includes(index))
        if (menuList.includes(4)) {
          let indexInsert = filteredSteps.findIndex(step => step.title === 'toxic') + 1
          const toxicCareers = careers.filter((career) => career.classification === CAREER_CLASSIFICATION.DEFIANT)
          toxicCareers.forEach((item, index) => {
            const textReplace = item.features_toxics.map((feature: any) => feature.name)
            const lastFeatureToxic = textReplace.pop()
            const textToReplace = (textReplace.length > 1 ? (textReplace.slice(0, textReplace.length).join(', ') + ' y ') : '') + lastFeatureToxic

            const toxic_tutorial_text = {
              target: '.career-defiant',
              title: 'toxic',
              content: `Esta carrera ;${item.career}; tiene estos elementos que me has dicho que no te gustan: ;${textToReplace};. Mírala con cuidado antes de tomar una decisión.`,
              floaterProps: {
                hideArrow: true
              },
              index,
              placement: 'center'
            }

            filteredSteps.splice(indexInsert, 0, toxic_tutorial_text)

            indexInsert++
          })
        }
      }
      if (current_step === 'STEP_4') {
        filteredSteps = STEPS_4.map(step => step)

        if (menuList.includes(4)) {
          let textReplace: string[] = []

          const toxicCareers = careers.filter((career) => career.classification === CAREER_CLASSIFICATION.DEFIANT)

          toxicCareers.forEach((item, index) => {
            textReplace = item.features_toxics.map((feature: any) => feature.name)
            const lastFeatureToxic = textReplace.pop()

            const textToReplace = (textReplace.length > 1 ? (textReplace.slice(0, textReplace.length).join(', ') + ' y ') : '') + lastFeatureToxic

            const toxic_tutorial_text = {
              target: '.career-defiant',
              title: 'toxic',
              content: `Esta carrera ;${item.career}; tiene estos elementos que me has dicho que no te gustan: ;${textToReplace};. Mírala con cuidado antes de tomar una decisión.`,
              floaterProps: {
                hideArrow: true
              },
              index,
              placement: 'center'
            }

            filteredSteps.push(toxic_tutorial_text)
          })
        }

        filteredSteps.push(
          {
            target: '.favourite-careers-toogle',
            content: 'Para tener mejores opciones y mostrarte las carreras que hemos elegido para ti, apaga este botón.',
            floaterProps: {
              hideArrow: true
            },
            placement: 'center'
          })
      }

      filteredSteps.push(
        {
          target: '.career-success',
          title: '',
          content: 'Selecciona una carrera ideal, óptima o complementaria y comienza a explorar. Para ello debes seleccionar “Ver instituciones”.',
          floaterProps: {
            hideArrow: true
          },
          placement: 'center'
        },
        {
          target: '.career-success',
          title: 'latest',
          content: '',
          floaterProps: {
            hideArrow: true
          },
          placement: 'center'
        }
      )

      if (tutorialStepList !== 'STEP_2') {
        setStepList([...filteredSteps])
      }

      setRunTutorial(firstTutorialStatus)
    }
  }

  const handleCallback = (data: any) => {
    if (!run) return

    const { action, index, type } = data

    if (action === 'next' && index === step && type === 'step:after') setStepTutorial(step + 1)
    if (action === 'prev' && index === step && type === 'step:after') setStepTutorial(step - 1)
    document.querySelectorAll('.joyride-element-show').forEach(function (elemento) {
      elemento?.classList.remove('joyride-element-show')
    })
    if (data.step?.target !== 'body' && !!data.step?.target) {
      const targetElements = document.querySelectorAll(data.step?.target)
      if (typeof data.step.index === 'number' && data.step.index >= 0 && data.step.index < targetElements.length) {
        targetElements.forEach((element, index) => {
          if (index === data.step.index) {
            element.classList.add('joyride-element-show')
          } else {
            element.classList.remove('joyride-element-show')
          }
        })
      } else {
        if (!firstTutorialStatus) {
          setTimeout(() => {
            targetElements.forEach((element) => {
              element.classList.add('joyride-element-show')
            })
          }, 300)
        } else {
          targetElements.forEach((element) => {
            element.classList.add('joyride-element-show')
          })
        }
      }
    }

    if (run && data.step.title === 'latest') {
      setDemoHover(true)
      document.querySelectorAll('.pointer-events-none').forEach(function (elemento) {
        elemento?.classList.remove('pointer-events-none')
      })
    }

    if (run && data.step?.title === 'favorite' && step === 2) {
      setDemoFavorite(true)
    } else if (run && data.step?.title === 'favorite' && step === 3) {
      setDemoFavorite(true)
    } else {
      setDemoFavorite(false)
    }

    if (type === 'tour:end') {
      if (tutorialStepList !== 'STEP_2') {
        setFavouriteStatus(firstTutorialStatus ? true : favouriteCourses)
        setRunTutorial(false)
        // setTutorialStatus(false)
        if (tutorialStepList === 'STEP_4') {
          setStepTutorial(0)
        }

        setRecord({
          ...record,
          favourite_courses: favouriteCourses,
          tutorial_step: tutorialStepList
          // demo: false
        })
      } else if (firstTutorialStatus) {
        setLoadingTutorial(true)
        setStepTutorial(0)
        setRunTutorial(false)
      }
      // if (run && tutorialStepList === 'STEP_4') setStepTutorial(step + 1)

      if (!firstTutorialStatus && secondTutorialStatus) {
        setDemoFavorite(false)
        setDemoCareerStatus(false)
        setUser({ ...user, demo_career: false })
      }
    }
  }

  const hadleSelectedOption = () => {
    // if (run && tutorialStepList === 'STEP_4') {
    //   setStepTutorial(step + 1)
    // }
    setValues(!favouriteCourses, setFavouriteCourses)
    setFavouriteStatus(!favouriteCourses)
    record.favourite_courses = !favouriteCourses
    setRecord({
      ...record,
      favourite_courses: !favouriteCourses
    })
  }

  const onLoadMore = () => {
    let _page = currPage
    _page = (currPage ?? 0) + 1
    setRecord({
      ...record,
      career_page: _page
    })
    setCurrPage(_page)
    setAllowCharge(true)
  }

  const onLoadLess = () => {
    if (careers?.length > 16) {
      setLoading(true)

      let _careers = careers

      _careers = _careers?.slice(0, currPage * 16)

      let _page = currPage
      _page = (currPage ?? 0) - 1

      setCurrPage(_page)
      setCareers(_careers)
      setRecord({
        ...record,
        careers: _careers,
        career_page: _page
      })

      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }

  useEffect(() => {
    if (allowCharge) {
      const abortControllerRef = new AbortController()
      const signal = abortControllerRef.signal
      // getCareerReferences()
      getCareers(signal)
      return () => abortControllerRef.abort()
    }
  }, [allowCharge])

  useEffect(() => {
    if (!run && menuList?.length > 0) {
      filterMenuSteps()
    }
  }, [menuList])

  useEffect(() => {
    const abortControllerRef = new AbortController()
    const signal = abortControllerRef.signal
    getCareers(signal, search)

    return () => abortControllerRef.abort()
  }, [search])

  // useEffect(() => {
  //   if ((!run && !firstTutorialStatus) || (!run && !secondTutorialStatus)) {
  //     setShowCareers([...careers])
  //   }
  //   if ((run && firstTutorialStatus) || (run && secondTutorialStatus)) {
  //     setShowCareers([...careers])
  //   }

  //   if (!run && firstTutorialStatus) {
  //     setShowCareers([...careers])
  //     // setLoadingTutorial(false)
  //     // setRunTutorial(true)
  //   }
  // }, [run])

  useEffect(() => {
    setTutorialByStep2({
      record: {
        ...record,
        tutorial_step: 'STEP_2',
        favourite_courses: favouriteCourses,
        careers
      }
    })
  }, [careers])

  useEffect(() => {
    if (!firstTutorialStatus && secondTutorialStatus && careers.length && !run) {
      setStepList([...CAREER_1])
      if (tutorialStepList === 'STEP_2') {
        setStepTutorial(2)
        setTimeout(() => {
          document.querySelectorAll('.card-career').forEach(function (elemento) {
            elemento?.classList.add('joyride-element-show')
          })
        }, 300)
      } else {
        setStepTutorial(0)
      }
      setLoadingTutorial(false)
      setRunTutorial(true)
    }
  }, [careers])

  const handleSatisfied = () => {
    setStepList([...favouriteCourses ? CAREER_3 : CAREER_1])
    setStepTutorial(step + 1)
  };

  const handleOtherOptions = () => {
    setStepList([...CAREER_2])
    setStepTutorial(step + 1)
  };

  useEffect(() => {
    if (careers.length > 0 && !run && firstTutorialStatus) {
      setRunTutorial(run)
      setLoadingTutorial(false)
      setStepTutorial(0)
      setMenuList(record.menu_list)
    }
  }, [careers])

  return (
    <article id="loading" className='bg-main-alt overflow-hidden'>
      {
        !loadingTutorial && careers.length > 0 && (
          <Joyride
            callback={handleCallback}
            steps={stepList}
            continuous
            hideCloseButton
            disableScrolling
            disableCloseOnEsc
            disableOverlayClose
            showSkipButton={false}
            stepIndex={step}
            run={run}
            tooltipComponent={(props) => (
              <IntroTooltip
                {...props}
                handleYes={handleSatisfied}
                handleNo={handleOtherOptions}
              />
            )}
            styles={{
              options: {
                arrowColor: '#60DFFF',
                overlayColor: 'rgba(0, 0, 12, 0.9)'
              }
            }}
          />
        )}
      <section className="w-screen h-screen quiz-screen-background flex flex-col pt-6 px-6 max-[480px]:pt-[22px] max-[480px]:px-0">
        <DashboardMenu currentPage={3} type='SIMPLE' logoPosition='TOP' />
        <div className={`w-full h-[50px] flex z-20 justify-between max-[480px]:h-auto max-[480px]:mb-[10px] max-[480px]:px-3.5 ${isMobile() ? 'favourite-careers-toogle' : ''}`}>
          <div className="flex items-center justify-between relative gap-x-4 max-[480px]:flex-col max-[480px]:gap-y-4 max-[480px]:w-full">
            <div className={`mt-[2px] z-10 w-[310px] h-[52px] bg-cover flex items-center justify-start gap-x-4 px-4 border border-t-[#fff] border-l-[#fff] border-r-[#fff] border-b-[#320a4e] border-b-[2px] rounded-tl-[30px] rounded-tr-[8px] max-[480px]:mt-0 max-[480px]:h-auto max-[480px]:border-none max-[480px]:w-full max-[480px]:justify-center ${isMobile() ? '' : 'dashboard-gradient'}`}>
              {
                !isMobile() &&
                <div className="w-[30px] h-[30px] rounded-full border border-[#ffffff7a] flex items-center justify-center hover:scale-105 hover:opacity-100">
                  <img src={logoCareers} alt="logo" width="12" height="12" />
                </div>
              }
              <p className={`text-white ff-cp--bold text-[20px] leading-none ${firstTutorialStatus ? 'z-[-1] opacity-10' : ''}`}>Carreras Candidatas</p>
            </div>
            <div className="w-full h-auto max-[480px]:flex max-[480px]:justify-between max-[480px]:gap-x-2">
              <div className={`relative flex items-center w-[200px] max-[480px]:w-full xl:w-[367px] top-[2px] ${firstTutorialStatus ? 'z-[-1] opacity-20' : ''}`}>
                <input
                  type="search"
                  value={search ?? ''}
                  onChange={handleSearch}
                  className="w-full z-10 text-white pl-[18px] text-[10px] h-[36px] bg-[#070625] rounded-full border border-solid border-white" placeholder="Escribe una carrera en mente..."
                />
                <button className="w-[26px] h-[26px] absolute top-[5px] right-[5px] z-10 bottom-[5px] bg-cover transition ease opacity-70 hover:opacity-100" style={{ backgroundImage: `url(${iconSearch})` }}></button>
              </div>
              {
                isMobile() &&
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    onClick={hadleSelectedOption}
                    value={favouriteCourses ? 1 : 0}
                    className="sr-only peer"
                    checked
                    disabled={!checkboxEnabled}
                  ></input>
                  <div className={`w-[66px] h-9 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-[#fff]/[.39] after:content-[''] after:absolute after:top-1.5 ${favouriteCourses ? 'after:left-[13px] peer-checked:bg-[#fff]' : 'after:-left-[19px] peer-checked:bg-[#070625]'} peer-checked:transition-all peer-checked:duration-300 peer-checked:ease-in after:bg-[#070625] after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all after:duration-300 dark:border-gray-600 peer-checked:border peer-checked:border-white`}></div>
                </label>
              }
            </div>
          </div>
          {
            !isMobile() &&
            <div className="flex items-center">
              <div className="w-[36px] h-[36px] rounded-full border-2 border-white overflow-hidden mr-2">
                <img src={avatarDefault} alt="" className="w-[36px] h-[36px]" />
              </div>
              <p className="ff-cp--bold text-[14px] xl:text-[20px] text-white whitespace-nowrap">
                {
                  user &&
                  user.name + ' ' + user.last_name
                }
              </p>
            </div>
          }
        </div>
        <div className={`w-full ${isMobile() ? 'h-[calc(100%-95px)]' : 'h-[calc(100%-110px)]'} flex flex-wrap relative dashboard-gradient overflow-hidden border-2 border-white/[.8] rounded-b-xl rounded-tl-none rounded-tr-[50px] max-[480px]:border-0 max-[480px]:rounded-none ${firstTutorialStatus || secondTutorialStatus ? '' : 'z-[11]'}`}>
          <div className="w-full h-full">
            <div id="careers-step" className="tutorial-career careers-step w-full h-full p-6 !overflow-y-auto !overflow-x-hidden max-[480px]:pb-2 max-[480px]:pl-3 max-[480px]:pr-2.5 max-[480px]:pt-0">
              <div className={`w-full h-full max-[480px]:pt-2 max-[480px]:border-t-white/[.8] max-[480px]:border-t-2 ${run && secondTutorialStatus ? 'pointer-events-none' : ''}`}>
                {
                  careers?.length > 0 && !loading && (
                    <div className="grid grid-cols-[repeat(auto-fit,210px)] gap-[20px] mb-14 max-[480px]:gap-x-1.5 max-[480px]:gap-y-2.5 max-[480px]:grid-cols-[repeat(auto-fit,160px)] max-[480px]:justify-center">
                      {
                        careers.map((data: any) => (
                          <CardCareer
                            key={`career-${data.career_id}`}
                            id={data.career_id}
                            HasInstitution={data.HasInstitution}
                            type="complex"
                            size={'xl'}
                            name={data.career}
                            career_reference_id={data.career_reference_id}
                            classification={data.classification}
                            percent={(data.percentage)?.toString()}
                            profiles={data.features_toxics}
                            currentPage={`/${url}/dashboard/careers`}
                            weight={data.priority}
                            onFavorited={demoFavorite}
                            onHovered={demoHover}
                            onSelected={onSelectedFavouriteCareer}
                            onDeselected={onDeselectedFavouriteCareer}
                          />
                        ))
                      }
                    </div>
                  )

                }
                {
                  loading &&
                  <div className="w-full h-full flex items-center justify-center">
                    <img src={iconLoading} alt="" className="w-[50px] h-[50px]" />
                  </div>
                }
                {
                  careers?.length >= 16 &&
                  <section className="text-right pt-6 pb-10 text-[#fff] text-[25px] ff-cp--bold z-[0] relative">
                    <TitleMessage
                      index={0}
                      useLoading={false}
                      size="w-[700px] max-w-[100%] min-w-[260px] h-auto pl-[12dvw] mt-[-10px]"
                      title=""
                      message="¿Estás satisfecho con las CARRERAS que te muestro?"
                    />
                    <div className="flex gap-x-2 items-center justify-center">
                      {
                        careers?.length > 16 &&
                        <button onClick={onLoadLess}
                          style={{ backgroundImage: `url(${btnViewLess})` }}
                          className="flex gap-4 w-[220px] h-[60px] max-[480px]:w-[180px] max-[480px]:h-[50px] justify-end cursor-pointer opacity-80 hover:opacity-100 bg-cover bg-no-repeat">
                        </button>
                      }
                      <button onClick={onLoadMore}
                        style={{ backgroundImage: `url(${btnViewMore})` }}
                        className="flex w-[220px] h-[60px] max-[480px]:w-[180px] max-[480px]:h-[50px] gap-4 justify-end cursor-pointer opacity-80 hover:opacity-100 bg-center bg-cover bg-no-repeat">
                      </button>
                    </div>
                  </section>

                }
              </div>
            </div>
          </div>
        </div>

         <div className="w-full flex-1 min-h-[50px] max-[480px]:min-h-fit flex items-center gap-x-8">
          {
            !isMobile() &&
            <div className="w-1/2 h-full flex items-center justify-start gap-x-10 pl-[45px] pr-[80px] xl:px-28">
              <div className="favourite-careers-toogle tutorial-career">
                <div className="flex gap-x-1 xl:gap-x-3">
                  <div className="w-[97px] h-[32px]">
                    <p className="ff-m--regular text-[14px] leading-none text-white">Carreras Seleccionadas</p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      onClick={hadleSelectedOption}
                      value={favouriteCourses ? 1 : 0}
                      className="sr-only peer"
                      checked
                      disabled={!checkboxEnabled}
                    ></input>
                    <div className={`w-[66px] h-8 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-[#fff]/[.39] after:content-[''] after:absolute after:top-1 ${favouriteCourses ? 'after:left-[13px] peer-checked:bg-[#fff]' : 'after:-left-[19px] peer-checked:bg-[#070625]'} peer-checked:transition-all peer-checked:duration-300 peer-checked:ease-in after:bg-[#070625] after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all after:duration-300 dark:border-gray-600 peer-checked:border peer-checked:border-white`}></div>
                  </label>
                </div>
              </div>
              <div className="flex gap-x-2">
                <div className="w-[89px] h-[32px]">
                  <p className="ff-m--regular text-[14px] leading-none text-white">Pregrado Obligatorio</p>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    onClick={handlePregrade}
                    value={pregradeValue ? 1 : 0}
                    className="sr-only peer"
                    checked
                    disabled={!checkboxEnabled}
                  ></input>
                  <div className={`w-[66px] h-8 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-[#fff]/[.39] after:content-[''] after:absolute after:top-1 ${pregradeValue ? 'after:left-[13px] peer-checked:bg-[#fff]' : 'after:-left-[19px] peer-checked:bg-[#070625]'} peer-checked:transition-all peer-checked:duration-300 peer-checked:ease-in after:bg-[#070625] after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all after:duration-300 dark:border-gray-600 peer-checked:border peer-checked:border-white`}></div>
                </label>
              </div>
            </div>
          }
          <div className="w-1/2 h-full flex items-center justify-end gap-x-10 pl-[80px] pr-[45px] xl:px-28">
           {
             !isMobile() &&
             <>
              <div className="flex gap-x-1 xl:gap-x-3 relative">
                <div className="w-[81px] h-[32px]">
                  <p className="ff-m--regular text-[14px] leading-none text-white">Nivel educativo</p>
                </div>
                <div onClick={() => setShowLevelPopup(!showLevelPopup)}
                  className="w-[96px] h-[32px] bg-[#070625] border border-white cursor-pointer flex items-center justify-between rounded-3xl px-2.5">
                  <p className="col-span-8 ff-m--bold text-[10px] leading-none text-white">{levelOptions.name}</p>
                  <img src={iconDoubleArrow} width="10" height="10" />
                </div>
              {
                showLevelPopup &&
                <div className="absolute bottom-9 right-0 z-50 bg-[#f9f9f9] w-[100px] h-fit rounded-lg py-2">
                  {
                    EDUCATION_LEVEL.map((level, index) => {
                      return (
                        <div
                          key={`education-level-${level.id}`}
                          className="h-[25px] px-2 flex items-center cursor-pointer hover:bg-[#e7e7e7]"
                          onClick={() => {
                            setValues(level, setLevelOptions)
                            localStorage.setItem('tcm_level_options', JSON.stringify(level).toString())
                            setShowLevelPopup(false)
                          }}
                        >
                          <p className="text-[#070625] text-[12px] leading-none">{level.name}</p>
                        </div>
                      )
                    })
                  }
                </div>
              }
              </div>
              <div className="flex gap-x-2 relative">
                <div className="w-[81px] h-[32px]">
                  <p className="ff-m--regular text-[14px] leading-none text-white">Tipo de gestión</p>
                </div>
                <div onClick={() => setShowAdministrationPopup(!showAdministrationPopup)} className="w-[96px] h-[32px] bg-[#070625] border border-white cursor-pointer flex items-center justify-between rounded-3xl px-2.5">
                  <p className="col-span-8 ff-m--bold text-[10px] leading-none text-white">{administrationOptions.name}</p>
                  <img src={iconDoubleArrow} width="10" height="10" />
                </div>
                {
                  showAdministrationPopup &&
                  <div className="absolute bottom-9 right-0 z-50 bg-[#f9f9f9] w-[100px] h-fit rounded-lg py-2">
                    {
                      ADMINISTRATION.map(administration => {
                        return (
                          <div
                            role="option"
                            key={`administration-${administration.id}`}
                            className="h-[25px] px-2 flex items-center cursor-pointer hover:bg-[#e7e7e7]"
                            onClick={() => {
                              setValues(administration, setAdministrationOptions)
                              localStorage.setItem('tcm_administration_options', JSON.stringify(administration).toString())
                              setShowAdministrationPopup(false)
                            }}
                          >
                            <p className="text-[#070625] text-[12px] leading-none">{administration.name}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                }
              </div>
             </>
           }
            <div className="nothing"></div>
          </div>
        </div>
      </section>
    </article>
  )
}

export default DashboardCareer
