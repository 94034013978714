import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Services
import { includeEvery } from '../../../common/methods/Includes'
import { getWorkspaceParams } from '../../../common/methods/params'
import { getRecord } from '../../../common/methods/storage'
import { getAllFeaturesByParentId, getAllFeaturesByType } from '../../../services/apis/feature'
import { createProfilesByList } from '../../../services/apis/profile'
import { getCalculatedVocationalProfile, getCurrentRecord, updateRecord } from '../../../services/apis/record'

// Components
import { ArrowLeft, ArrowRight } from '../../../components/arrows/SliderArrow'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import ButtonStar from '../../../components/shared/ButtonStar'

// Constants
import { PAGES } from '../../../constants/Pages'
import { profile_stat } from '../../../constants/lists/profiles'

// Images
import Slider from 'react-slick'
import arrowWhiteRight from '../../../assets/images/arrows/arrow-white-right.svg'
import arrowWhite from '../../../assets/images/arrows/arrow-white.svg'
import burblePopup from '../../../assets/images/burble-popup.svg'
import cardTitle from '../../../assets/images/cards/card_title_profile.svg'
import starVerbGreen from '../../../assets/images/stars/star-verbs-green.svg'
import starVerbBlue from '../../../assets/images/stars/star-verbs-light-blue.svg'
import starVerbRed from '../../../assets/images/stars/star-verbs-red.svg'
import starVerbYellow from '../../../assets/images/stars/star-verbs-yellow.svg'
import { isMobile } from '../../../common/methods/devices'
import TitleMessage from '../../../components/shared/TitleMessage'
import { REACTIONS } from '../../../constants/reactions'

const ProfileDetail = () => {
  const record = getRecord()
  const { token } = useParams()
  const { url } = getWorkspaceParams()

  const navigate = useNavigate()

  const sliderRef = useRef<any>(null)

  const [features, setFeatures] = useState<any[]>([])

  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const [selectReaction, setSelectReaction] = useState<{ id: string, reaction: 'LOVE' | 'N/A' }[]>([])

  const [currentIndex, setCurrentIndex] = useState<number>(1)
  const [showNextButton, setShowNextButton] = useState<boolean>(false)

  const VERBS_POSITIONS = {
    motivation: 'top-[37.5%] left-[-3.5%] max-[480px]:top-[33%] max-[480px]:left-0',
    need: 'top-[16%] left-[1.5%] max-[480px]:top-[18%] max-[480px]:left-[5%]',
    values: 'top-[16%] right-[1%] max-[480px]:top-[18%] max-[480px]:right-[5%]',
    internal_tools: 'top-[60.5%] left-[0.5%] max-[480px]:top-[49%] max-[480px]:left-[5%]',
    external_tools: 'top-[60.5%] right-[0%] max-[480px]:top-[49%] max-[480px]:right-[5%]',
    profile_verbs: 'top-[37.5%] right-[-4%] max-[480px]:top-[33%] max-[480px]:right-0'
  }

  const STAR_VERBS = [
    starVerbRed,
    starVerbYellow,
    starVerbGreen,
    starVerbBlue
  ]

  const settings = {
    className: 'slick-center-slider',
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    draggable: false,
    afterChange: (index: number) => {
      setCurrentIndex(index + 1)
    }
  }

  const getVerbsByParent = async (feature_id: string) => {
    return await getAllFeaturesByParentId(feature_id)
      .then((result: any) => {
        if (result.status) {
          return result.data
        }
      })
  }

  const getFeatures = async () => {
    await getAllFeaturesByType('profile')
      .then(result => {
        if (result.status) {
          mixedDataChildren(result.data)
        }
      })
  }

  const getProfiles = () => {
    if (record) {
      const profiles = record.features.filter((feature: any) => feature.type === 'profile')
      const profileList = profiles.map((profile: any) => ({ id: profile.id, reaction: profile.profiles.reaction }))
      setSelectReaction([...profileList])
    }
  }

  const setSelectedReaction = async ({ id, reaction } : { id: string, reaction: 'N/A' | 'LOVE' }) => {
    const selectedList = selectReaction;
    const feature = features.find(feature => feature.id === id)

    const index = selectReaction.findIndex(item => item.id === id)

    if (index === -1) {
      selectedList.push({ id, reaction })
    } else {
      selectedList[index] = { id, reaction }
    }

    await createProfilesByList([{ feature_id: id, reaction, selected: true, weight: null, feature }])
    setSelectReaction([...selectedList])
  }

  const getSelectedReaction = (feature_id: string) => {
    const selected = selectReaction.find(item => item.id === feature_id)
    return selected?.reaction ?? ''
  }

  const handleNextClick = () => {
    if (sliderRef.current && currentIndex < features.length) {
      sliderRef.current.slickNext()
    }
  }

  const mixedDataChildren = async (features: any[]) => {
    const featureFormattedList = []

    for (const feature of features) {
      const children = await getVerbsByParent(feature.id)
      featureFormattedList.push({ ...feature, children })
    }

    setFeatures(featureFormattedList)
  }

  const onGoToNextStep = async () => {
    const selectedList = selectReaction.map(feature => {
      const _featureData = features.find(_feature => _feature.id === feature.id)
      return { feature_id: feature.id, reaction: feature.reaction, selected: true, weight: null, feature: _featureData }
    })
    await getCalculatedVocationalProfile()
      .then(async (result: any) => {
        const { profiles } = result
        const profileCalculatedIds: any[] = profiles.filter((profile: any) => profile.status).map((profile: any) => profile.id)
        const selectedProfileIds = selectedList.filter((profile) => profile.selected && profile.reaction === REACTIONS.LOVE).map((profile) => profile.feature_id)
        let nextPage = '/loading/a460712c-9213-11ed-a1eb-0242ac120002'

        if (profileCalculatedIds.length !== selectedProfileIds.length || !includeEvery(profileCalculatedIds, selectedProfileIds)) {
          nextPage = isMobile() ? PAGES.LOADING_2 : PAGES.DECISION_3
        }

        await updateRecord({ step: 27 })
          .then(async () => {
            await getCurrentRecord()
              .then(() => {
                navigate(`/${url}${nextPage}`)
              })
          })
      })
  }

  useEffect(() => {
    getFeatures()
    getProfiles()
  }, [token])

  useEffect(() => {
    handleNextClick()
    if (selectReaction.length === 4 && !showNextButton) setShowConfirm(true)
  }, [selectReaction])

  return (
        <>
          <article className="bg-main bg-feature-big" id="home">
            <section className="w-screen h-screen cloud-page py-4 flex flex-col items-center justify-center gap-y-2">
                <ButtonRedirect action="return" linkReturn={PAGES.PROFILES} text="return" bubbleSize='big' />
                { showNextButton &&
                  <ButtonRedirect
                    action="next"
                    style='simple'
                    callback={() => {
                      setShowConfirm(true);
                      setShowNextButton(false);
                    }}
                    linkReturn={PAGES.PROFILE_DETAIL}
                    text="next" /> }
                <BadgeStep currentStep={24} bubbleSize='big' />
                <div className='profile-slider h-full relative rounded-[20px] z-[120!important] flex flex-col py-2 max-[480px]:py-0 gradient-purple'>
                  <Slider
                        {...settings}
                        ref={sliderRef}
                        prevArrow={
                          <ArrowLeft
                            arrow={arrowWhite}
                            width="w-[50px!important]"
                            height="h-[50px!important]"
                          />
                        }
                        nextArrow={
                          <ArrowRight
                            arrow={arrowWhiteRight}
                            onClick={handleNextClick}
                            width="w-[50px!important]"
                            height="h-[50px!important]"
                            count={features.length}
                            canShow={getSelectedReaction(features[currentIndex - 1]?.id)}
                          />
                        }
                      >
                    {
                      features.map((feature, index) => {
                        const profileData = profile_stat.find(profile => feature.id === profile.id)
                        return (
                          <>
                            {
                              !profileData?.id
                                ? <></>
                                : <div className='w-[645px] max-[480px]:w-[calc(100dvw-5px)] h-full flex flex-col'>
                                  <div className={`h-[135px] text-center z-50 px-10 py-5 bg-center bg-no-repeat bg-[size:auto_100%] text-[24px] max-[480px]:max-w-[100%] max-[480px]:min-w-[50%] max-[480px]:w-[calc(100dvw-5px)] max-[480px]:h-[auto] max-[480px]:py-3 max-[480px]:px-[25px] max-[480px]:bg-center max-[480px]:aspect-[3.5] max-[480px]:content-center ${profileData.boxShadow}`} style={{ backgroundImage: `url(${isMobile() ? profileData.bubble_mobile : profileData.bubble})` }}>
                                  <p className={`${profileData.mainColor} text-[22px] max-[480px]:text-[14px] max-[480px]:leading-[15px]`}>
                                    Hola soy {profileData.avatarName} y soy
                                    <label className='lowercase'> {profileData.name}</label>
                                  </p>
                                  <p className="ff-m--regular text-sm leading-[15px] text-white text-center max-[480px]:text-[11px] max-[480px]:leading-[12px]">{profileData.description}</p>
                                </div>
                                  <div className='w-full h-full relative flex justify-center items-center max-[480px]:h-[calc(100dvh-((100dvw-5px)*10/35))]'>
                                    <img className='w-[180px] z-[160] max-[480px]:w-[90px] aspect-square absolute top-[5px] left-[19.5%] max-[480px]:top-[12%]' src={profileData.brand} alt='brand' />
                                    <div
                                      className={`w-[355px] h-[520px] z-50 bg-no-repeat bg-center bg-cover max-[480px]:w-[80%] max-[480px]:h-[auto] max-[480px]:aspect-[.7] ${profileData.dropColor}`}
                                      style={{ backgroundImage: `url(${profileData.profile})` }}>
                                    </div>
                                    <div className="absolute w-full h-full m-auto top-[3.5%]">
                                      {feature?.children?.length > 0 &&
                                        feature.children?.map((verb: any) => {
                                          const position = VERBS_POSITIONS[verb.type as 'motivation' | 'values' | 'need' | 'internal_tools' | 'external_tools']
                                          const starImage = STAR_VERBS[index]
                                          return (
                                            <div key={verb.name} className={`absolute z-[100] flex flex-col items-center justify-center ${position} w-[90px] ${profileData.dropColor}`}>
                                              <img className='w-[88px] h-[95px] max-[480px]:w-[60px] max-[480px]:h-[65px]' src={starImage} alt={verb.name} />
                                              <label className="text-center ff-cp--bold text-white text-xs max-[480px]:text-[11px] max-[480px]:leading-[12px]">{verb.name}</label>
                                            </div>
                                          )
                                        })}
                                    </div>
                                    <div className='absolute bottom-[19%] z-[120] bg-center bg-cover w-[248px] h-[54px] flex items-center justify-center text-white text-lg max-[480px]:w-[165px] max-[480px]:h-[38px] max-[480px]:bottom-[25%] max-[480px]:text-[13px] max-[480px]:leading-[14px]'
                                      style={{ backgroundImage: `url(${profileData.card_title})` }}>
                                      {profileData.name}
                                    </div>
                                  </div>
                                  <div className='w-[120%] max-[480px]:w-[auto] max-[480px]:left-[10px] max-[480px]:right-[10px] absolute bottom-[12%] -left-[10%] flex gap-x-4 justify-between items-center z-[150]'>
                                    <button
                                      onClick={() => setSelectedReaction({ id: feature.id, reaction: 'N/A' })}
                                      className='w-[220px] h-[78px] max-[480px]:w-[150px] max-[480px]:h-[54px] max-[480px]:text-[13px] max-[480px]:leading-[14px] max-[480px]:pb-[8px] bg-cover bg-no-repeat flex items-center justify-center pt-5 text-white text-[18px] transition-all duration-200 hover:scale-105'
                                      style={{ backgroundImage: `url(${getSelectedReaction(feature.id) === 'N/A' ? profileData.card_selected : cardTitle})` }}>
                                      No me identifico
                                    </button>
                                    <button
                                      onClick={() => setSelectedReaction({ id: feature.id, reaction: 'LOVE' })}
                                      className='w-[220px] h-[78px] max-[480px]:w-[150px] max-[480px]:h-[54px] max-[480px]:text-[13px] max-[480px]:leading-[14px] max-[480px]:pb-[8px] bg-cover bg-no-repeat  flex items-center justify-center pt-5 text-white text-[18px] transition-all duration-200 hover:scale-105'
                                      style={{ backgroundImage: `url(${getSelectedReaction(feature.id) === 'LOVE' ? profileData.card_selected : cardTitle})` }}>
                                      Me identifico
                                    </button>
                                  </div>
                                </div>
                            }
                          </>
                        )
                      })
                    }
                  </Slider>
                </div>
            </section>
        </article>
        {
          showConfirm &&
          <div className={'w-full h-full fixed inset-0 bg-[#070625]/[.8] flex items-center justify-center delay-1000 overflow-hidden z-[120] max-[480px]:z-[2000]'} style={{ transition: 'height ease 1200ms' }}>
              <div className="rounded-full h-[90%] max-[480px]:w-full max-[480px]:h-[auto] aspect-square p-[9px] grid justify-self-center bg-center bg-no-repeat bg-cover max-[480px]:px-0" style={{ backgroundImage: `url(${burblePopup})` }}>
              <div className="w-full h-full rounded-full flex flex-col items-center justify-center pt-20">
                  <div className="w-[110%] justify-self-center">
                  <TitleMessage
                      index={0}
                      useLoading={false}
                      size="w-full h-auto pl-[22%]"
                      title=""
                      message="¿Estás seguro de lo que has seleccionado?"
                  />
                  </div>
                  <div className="w-full flex justify-center gap-x-14">
                      <ButtonStar
                        text='No, déjame pensarlo'
                        type='white'
                        textSize='max-[480px]:text-[10px]'
                      callback={() => {
                        setShowConfirm(false);
                        setShowNextButton(true)
                      }} />
                      <ButtonStar
                          text="¡Sí, vamos!"
                          type='white'
                          textSize='max-[480px]:text-[10px]'
                          callback={ onGoToNextStep }
                      />
                  </div>
              </div>
              </div>
          </div>
        }
      </>
  )
}

export default ProfileDetail
